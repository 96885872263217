import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from "gatsby-plugin-image"
import { Image } from './banner-css';

const BannerImage = ({ image, ...props }) => (
  <Image {...props}>
    <GatsbyImage image={image.src} alt={image.alt} />
  </Image>
);

BannerImage.propTypes = {
  image: PropTypes.shape({
    src: PropTypes.object.isRequired,
    alt: PropTypes.string.isRequired,
  }).isRequired,
};

export default BannerImage;
