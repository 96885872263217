import styled from 'styled-components';
import config from '../../../site-config';

export default styled.ul`
  display: inline-flex;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  list-style-type: none;
  > li:not(:last-child) {
    margin-right: 1rem;
  }
  a {
    color: #000;
    font-size: 2rem;
    &:hover {
      color: ${config.themeColor};
    }
  }
`;
