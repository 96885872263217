import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Heading } from 'rebass/styled-components';
import { rgba } from 'polished';
import { themeColor } from '../../site-config';
import Layout from '../components/layout/layout';
import Metadata from '../components/metadata/metadata';
import { ContentOverlayBanner } from '../components/banner';
import Social from '../components/social/social';

const IndexPage = ({ data }) => {
  const image = {
    src: data.bannerImg.childImageSharp.gatsbyImageData,
    alt: 'NYC',
  };

  return (
    <Layout>
      <Metadata pageData={{ ...data.site.siteMetadata }} />
      <ContentOverlayBanner image={image} contentAlign="center" orient="left" imagePosition="center" bg={rgba(themeColor, 0.2)} fullscreen>
        <Heading fontWeight={400} fontSize="5rem" lineHeight={1} mb={1} fontFamily="Source Sans Pro">Ernie Bello</Heading>
        <Social />
      </ContentOverlayBanner>
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }).isRequired,
};

export default IndexPage;

export const query = graphql`
  query HomepageQuery {
    site {
      siteMetadata {
        title: siteTitle
        description: siteDescription
      }
    },
    bannerImg: file(relativePath: { eq: "nyc.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
        )
      }
    }
  }
`;
